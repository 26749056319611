import { store } from './vue-store.js';
import { trans } from 'vue-components/src/mixin/trans.js';
import desktopSideMenu from '../components/desktop-side-menu/desktop-side-menu.vue';
import desktopHeader from '../components/header/desktop-header.vue';
import { registerAxiosInterceptor } from '../libs/axios-interceptor.js';
import { setNewCSRF } from '../libs/csrf-token.js';
import { isBrowserUpdateNeeded } from '@/libs/browser-check.js';
import siteWarning from '@/components/header/site-warning.vue';
import { mixpanelMixin } from './mixpanel.js';

export const setup = (app) => {
    app.config.performance = true;
    app.config.productionTip = false;
    app.mixin(trans);
    app.mixin(mixpanelMixin);
};

export default {
    el: '.content',

    components: {
        desktopHeader,
        desktopSideMenu,
        siteWarning,
    },

    store,

    computed: {
        getUrlPathname() {
            return window.location.pathname;
        },
        // Paths where we don't want to dispatch getUser
        pathsExcludedFromGetUser() {
            return ['/ordre/aksept'];
        },
    },

    beforeCreate() {
        if (isBrowserUpdateNeeded()) {
            this.$store.dispatch('client/setBrowserBanner', {
                enabled: true,
            });
        }
    },

    created() {
        registerAxiosInterceptor();

        if (!this.pathsExcludedFromGetUser.includes(this.getUrlPathname)) {
            store.dispatch('getUser');
        }
    },

    methods: {
        selectProject(input) {
            store.dispatch('setCurrentProject', input);
        },
        setNewCSRF,
    },
};
