<template>
    <div class="desktop-header">
        <a
            v-if="showLaravelPulseIcon"
            href="/pulse"
            class="header-icon-button"
        >
            <img src="/images/svg/laravel-pulse-logo.svg" alt="laravel pulse logo">
            <span>Pulse</span>
        </a>

        <a
            v-if="showMailbookIcon"
            href="/mailbook"
            class="header-icon-button"
        >
            <fa-icon :icon="['fal', 'envelope']" />
            <span>Mailbook</span>
        </a>

        <send-feedback />

        <send-sms v-if="smsSettings.displayButton" />

        <a
            href="http://support.kvalitetskontroll.no/nb"
            target="_blank"
            rel="noopener"
            class="header-icon-button"
        >
            <fa-icon :icon="['fal', 'comment-question']" />
            <span>{{ trans('shared.Support') }}</span>
        </a>

        <a
            href="https://www.kvalitetskontroll.no/ressurser"
            class="header-icon-button"
            target="_blank"
            rel="noopener"
        >
            <fa-icon :icon="['fal', 'clapperboard-play']" />
            <span>{{ trans('shared.Webinarer') }}</span>
        </a>

        <a
            href="/mobile"
            class="header-icon-button"
        >
            <fa-icon :icon="['fal', 'mobile']" />
            <span>{{ trans('shared.Mobil') }}</span>
        </a>

        <login-popup
            v-if="isLoggedIn && hasUserInfo && loginTimedout"
            desktop
        />
    </div>
</template>

<script>
// Mixin
import featureAvailable from './../../mixins/featureAvailable.js';

import sendFeedback from '@/components/send-feedback/send-feedback.vue';
import loginPopup from '@/components/login-popup/login-popup.vue';
import sendSms from '@/views/send-sms/send-sms.vue';
import { ks } from 'vue-components';
import { mapState } from 'vuex';
import { refreshCSRF } from '../../libs/csrf-token.js';
import { Features } from '../../config/features.js';

let csrfInterval = null;

export default {
    name: 'desktop-header',

    components: {
        sendFeedback,
        loginPopup,
        sendSms,
    },

    mixins: [
        featureAvailable,
    ],

    props: {
        isLoggedIn: {
            type: Boolean,
            default: true,
        },
    },

    data: () => ({
        showLoginPopup: false,
    }),

    computed: {
        ...mapState({
            user: state => state.user,
            loginTimedout: state => state.user.loginTimedout,
            smsSettings: state => state.sms.settings,
        }),

        hasUserInfo() {
            return '$store' in this && 'state' in this.$store && 'user' in this.$store.state;
        },

        showMailbookIcon() {
            return this.isFeatureAvailable(Features.MAIN_HEADER_MAILBOOK);
        },

        showLaravelPulseIcon() {
            return this.isFeatureAvailable(Features.MAIN_HEADER_LARAVEL_PULSE) || this.user.roles.superAdmin;
        },
    },

    created() {
        this.$store.dispatch('sms/getSettings').catch(() => {
            ks.alert(
                Lang.get('errors.Feil'),
                Lang.get('sms-settings.Lasting av SMS-innstillinger feilet'),
            );
        });
        // Refresh CSRF token once a minute
        csrfInterval = setInterval(this.refreshCSRF, 60000);
        this.addEvents();
    },

    beforeUnmount() {
        this.removeEvents();
        clearInterval(csrfInterval);
    },

    methods: {
        addEvents() {
            // Try and update CSRF when device goes back online
            window.addEventListener('online', this.refreshCSRF);

            // Trigger CSRF update when window/tab is re-focused
            if (typeof document.hidden !== 'undefined') {
                window.addEventListener('visibilitychange', this.visibilityChangeEvent);
            } else {
                window.addEventListener('focus', this.refreshCSRF);
            }

            window.addEventListener('show-login-popup', this.onShowLoginPopup);
        },

        // Still need to put it into methods, so the method's `this` reference will refer to Vue
        refreshCSRF,

        removeEvents() {
            window.removeEventListener('online', this.refreshCSRF);

            // Trigger CSRF update when window/tab is re-focused
            if (typeof document.hidden !== 'undefined') {
                window.removeEventListener('visibilitychange', this.visibilityChangeEvent);
            } else {
                window.removeEventListener('focus', this.refreshCSRF);
            }

            window.removeEventListener('show-login-popup', this.onShowLoginPopup);
        },

        visibilityChangeEvent() {
            if (!document.hidden) {
                this.refreshCSRF();
            }
        },

        onShowLoginPopup() {
            this.$store.dispatch('setLoginTimedout', true);
        },
    },
};
</script>

<style lang="scss" scoped>
.desktop-header {
    display: flex;
    gap: 16px;
    justify-content: center;
    align-items: center;
    margin-right: 32px;
}
</style>
