<template>
    <transition name="slide">
        <aside v-show="menu.show">
            <slot name="links" />
        </aside>
    </transition>
</template>

<script>

export default {
    name: 'side-menu',

    props: {
        menu: {
            type: Object,
            required: true,
            default() {
                return {};
            },
        },
    },

    mounted() {
        this.setActiveLinkItem();
        this.makeSectionsCollapsible();
    },

    methods: {
        setActiveLinkItem() {
            const pathname = document.location.pathname;
            const pathnameWithoutIds = pathname.replace(/[/\d]+$/, '');
            const pathnameNumberStripped = pathname.replace(/\d+/, '');

            const pathsToCheck = [
                pathname,
                pathnameWithoutIds,
                pathnameNumberStripped,
            ];

            if (pathnameNumberStripped === '/prosjekt//veiviser') {
                this.menu.active = 'projects';
            }

            // Contacts does not have a side menu with children it's an direct link. Therefore we have to check for it explicitly.
            if (pathname === '/kontakter' || '/kontakter' === pathnameWithoutIds) {
                this.menu.active = 'contacts';

                return;
            }

            // Highlight projects as the active sub-menu if menu is sticky and we are on the front page
            if ('/' === pathname && true === this.menu.sticky) {
                return this.menu.active = 'projects';
            }

            // Documents has some custom redirect logic, so we cannot put the links inside a side-menu item. Therefore, we only activate the "main" category it belongs to.
            if ('/prosjekt/dokument' === pathnameWithoutIds) {
                return this.menu.active = 'projects';
            }

            if ('/personal/dokument' === pathnameWithoutIds) {
                return this.menu.active = 'hr';
            }

            if ('/maler/dokument' === pathnameWithoutIds) {
                this.menu.parent = 'templates';

                return this.menu.active = 'templates';
            }

            if ('/settings' === pathnameWithoutIds) {
                this.menu.active = 'settings';

                return;
            }

            const aElements = Array.from(this.$el.querySelectorAll('a'));

            // Add active class the closest li to the provided element and the closest main category (Bedrift, Timer, Personal...)
            const makeActive = (element) => {
                element.closest('li').classList.add('active');
                element.closest('section').classList.add('open');
                const activeParent = element.closest('div[data-parent]').dataset.parent;
                this.menu.active = activeParent;
                this.menu.parent = activeParent;

                let documentTitle = element.textContent;

                // Place in setTimeout so the outer menu is ready and we can query the ul > li there
                setTimeout(() => {
                    const outerNavElement = document.querySelector('ul.main li.active');

                    if (
                        outerNavElement
                        && outerNavElement.textContent
                        && outerNavElement.textContent !== documentTitle
                    ) {
                        documentTitle = `${outerNavElement.textContent} > ${documentTitle}`;
                    }

                    if (documentTitle) {
                        document.title = `${documentTitle} | Kvalitetssystem fra Kvalitetskontroll`;
                    }
                });
            };

            // Check first if the url (origin+pathname only - drop hash and search params) is the same as one of the a tags´s hrefs
            // or check with regex if the tag´s href is the same as url if we remove the url´s ending ids.
            // examples: "admin/prosedyre/1/2" -> "admin/prosedyre", "admin/prosedyre/1" -> "admin/prosedyre"
            const href = document.location.origin + pathname;
            const match = aElements.find(a => a.href === href || a.href === href.replace(/[/\d]+$/, ''));

            if (match) {
                makeActive(match);

                return;
            }

            // There is no href match, so check if we have a match in our comma seporated custom attrubute "data-alt-href"
            const aElementsWithData = Array.from(aElements.filter(a => a.dataset.altHrefs));

            for (let a of aElementsWithData) {
                const altHrefs = a.dataset.altHrefs.split(',');

                if (altHrefs.some(altHref => pathsToCheck.includes(altHref.trim()))) {
                    makeActive(a);

                    return;
                }
            }
        },

        /**
         * Make all sections in the side-bar that has more than 1 child become a toggleable collapse
         * If you were looking for the magic, this is where it happens.
         */
        makeSectionsCollapsible() {
            const allUl = Array.from(this.$el.querySelectorAll('ul'));
            const hasChildren = allUl.filter(ul => ul.childElementCount > 1);

            hasChildren.forEach((ul) => {
                const section = ul.closest('section');
                section.classList.add('collapsible');
                section.addEventListener('click', (e) => {
                    if (e.target !== e.currentTarget && !e.target.classList.contains('collapsible-header')) {
                        return;
                    }
                    section.classList.toggle('open');
                });
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.slide-enter-active {
    white-space: nowrap;
    overflow: hidden;
}

.slide-enter, .slide-leave-to {
    white-space: nowrap;
    width: 0;
    padding-left: 0;
    opacity: 0;
    overflow: hidden;
}

.hover > aside {
    transition: left 160ms ease-in-out;
    position: absolute;
    top: 0;
    left: 14vh;
    // Stop responsive left when menu no longer is growing/shrinking
    @media (max-height: 510px) {
        left: 90px;
    }
    @media (min-height: 850px) {
        left: 120px;
    }
}
aside {
    box-sizing: border-box;
    height: 100%;
    padding: 24px 0 24px 24px;
    width: 220px;
    background-color: #FFF;
    box-shadow: 0 3px 10px 0 rgba(0,0,0,0.15);
    overflow-y: auto;
    z-index: 997;
    word-break: break-word;
    transition: all .3s ease-in-out;

    :deep(section) {
        display: flex;
        margin-top: 30px;
        align-items: center;
        user-select: none;
        transition: display 500ms ease-in-out;
        position: relative;

        &.collapsible {
            cursor: pointer;

            &::after {
                font-family: "Font Awesome 5 Pro";
                font-weight: 900;
                content: '\f078';
                right: 15px;
                top: 0;
                position: absolute;
                font-size: 13px;
                color: #939393;
            }

            &.open {
                li {
                    display: block;
                    &:first-of-type {
                        margin-bottom: 15px;
                    }
                }
                &::after {
                    transform: rotate(180deg)
                }
            }

            li:first-of-type {
                max-width: 140px;
            }
        }

        &:first-of-type {
            margin-top: 0;
        }

        .icon {
            font-size: 18px;
            margin-right: 10px;
            width: 23px;
            align-self: flex-start;
        }

        ul {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
                color: #332E38;
                margin: 9px 5px 9px 0;
                font-size: 14px;
                letter-spacing: 0.17px;
                display: none;
                line-height: 1;

                &:first-of-type {
                    margin-top: 0;
                    font-weight: 500;
                    color: #1d1d1d;
                    display: block;
                }
                &:last-of-type {
                    margin-bottom: 0;
                }
                &.active {
                    color: #0AAEF3;
                }

                a {
                    color: inherit;
                    text-decoration: none;
                    &:hover {
                        color: #0AAEF3;
                    }
                }

                ul {
                    margin: 15px 0 0 20px;
                    li {
                        font-weight: normal !important;
                    }
                }
            }
        }
    }
}
</style>
