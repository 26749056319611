<template>
    <div>
        <section class="button-section" @click.prevent="onButtonClick">
            <slot name="sms-button">
                <button class="header-icon-button">
                    <font-awesome-icon :icon="['fal', 'comment-lines']" />
                    <span>{{ trans('sms-dialog.Ny SMS') }}</span>
                </button>
            </slot>
        </section>

        <kk-modal-v2
            v-if="modalOpen"
            size="large"
            type="separated"
            @hide="modalOpen = false"
            @ok="send"
        >
            <template #title>
                <div v-if="!hasErrors()">{{ trans('sms-dialog.Ny SMS') }}</div>
                <div v-if="hasErrors()">{{ trans('sms-dialog.Feil under sending av SMS') }}</div>
            </template>
            <template #content>
                <div class="sms-content">
                    <div class="content-wrapper">
                        <div id="sms-left" class="sms-left">
                            <div class="sub-title">
                                {{ trans('sms-dialog.Velg fra adresseboken') }}
                            </div><br>
                            <kk-contacts
                                v-model="smsObj.receivers"
                                type="normal"
                                :max="99999"
                                :classes="['desktop']"
                                :contacts="allContacts"
                                :categories="categories"
                                :last-used="[]"
                                primary="mobile"
                                hide-cancel
                                hide-ok
                            />
                        </div>

                        <div id="sms-right" class="sms-right">
                            <div class="right-scroll-wrapper">
                                <div class="sms-label">
                                    {{ trans('sms-dialog.Avsender') }}:
                                </div>
                                <vue-select
                                    v-model="smsObj.senderName"
                                    label="sender_name"
                                    :placeholder="getLang('sms-dialog.Velg avsendernavn')"
                                    :options="senderNames"
                                    :reduce="option => option.id"
                                    :clearable="false"
                                    class="sender-select"
                                >
                                    <template #open-indicator="{ attributes }">
                                        <fa-icon v-bind="attributes" :icon="['fal', 'chevron-down']" />
                                    </template>
                                </vue-select>
                                <div class="sms-label">
                                    {{ trans('sms-dialog.Mottakere') }}:
                                </div>
                                <span v-if="hasErrors()" class="label error-title">
                                    <br>
                                    <span class="error-label">
                                        <!-- eslint-disable-next-line -->
                                        {{ trans('sms-dialog.En eller flere mottakere kunne ikke motta meldingen. Mottakerne er markert med rødt.') }}
                                    </span>
                                </span>
                                <div id="mottakere" class="receivers-component">
                                    <div v-for="contact in receiversList" :key="contact.id">
                                        <!--Team tag-->
                                        <div
                                            v-if="contact.type === 'team' && !contact.error"
                                            id="tag"
                                            :ref="contact.personId"
                                            class="tag team"
                                            @click.prevent="showRecipients(contact)"
                                        >
                                            <font-awesome-icon :icon="['fal', 'users']" />
                                            <span>
                                                {{ contact.name }}
                                                ({{ getRecipients(contact).length }}
                                                {{ getRecipients(contact).length > 1 ?
                                                    getLang('sms-dialog.mottakere') :
                                                    getLang('sms-dialog.mottaker') }})
                                            </span>
                                            <button @click.stop="removeSelectedContact(contact)">
                                                <font-awesome-icon :icon="['fal', 'times']" />
                                            </button>
                                        </div>
                                        <!--Position tag-->
                                        <div
                                            v-if="contact.type === 'position' && !contact.error"
                                            id="tag"
                                            :ref="contact.personId"
                                            class="tag position"
                                            @click.prevent="showRecipients(contact)"
                                        >
                                            <font-awesome-icon :icon="['fal', 'hammer']" />
                                            <span>
                                                {{ contact.name }}
                                                ({{ getRecipients(contact).length }}
                                                {{ getRecipients(contact).length > 1 ?
                                                    getLang('sms-dialog.mottakere') :
                                                    getLang('sms-dialog.mottaker') }})
                                            </span>
                                            <button @click.stop="removeSelectedContact(contact)">
                                                <font-awesome-icon :icon="['fal', 'times']" />
                                            </button>
                                        </div>
                                        <!--Default tag-->
                                        <div
                                            v-if="
                                                contact.type !== 'team'
                                                    && contact.type !== 'position'
                                                    && !contact.error
                                            "
                                            id="tag"
                                            ref="tag-{{contact.id}}"
                                            class="tag person"
                                        >
                                            <span v-if="contact.name !== '' && contact.mobile !== '+A000'">
                                                {{ contact.name }} ({{ getNumberObject(contact.mobile).toString() }})
                                            </span>
                                            <span v-if="contact.name === '' && contact.mobile !== '+A000'">
                                                {{ contact.mobile }}
                                            </span>
                                            <span v-if="contact.mobile === '+A000'">{{ contact.name }}</span>
                                            <button @click.stop="removeSelectedContact(contact)">
                                                <font-awesome-icon :icon="['fal', 'times']" />
                                            </button>
                                        </div>

                                        <!--ERROR TAGS-->

                                        <!--Team tag-->
                                        <div
                                            v-if="contact.type === 'team error' && contact.error"
                                            id="tag"
                                            :ref="contact.personId"
                                            class="tag team"
                                            @click.prevent="showRecipients(contact)"
                                        >
                                            <font-awesome-icon :icon="['fal', 'users']" />
                                            <span>
                                                {{ contact.name }}
                                                ({{ getRecipients(contact).length }}
                                                {{ getRecipients(contact).length > 1 ?
                                                    getLang('sms-dialog.mottakere') :
                                                    getLang('sms-dialog.mottaker') }})
                                            </span>
                                            <button @click.stop="removeSelectedContact(contact)">
                                                <font-awesome-icon :icon="['fal', 'times']" />
                                            </button>
                                        </div>
                                        <!--Position tag-->
                                        <div
                                            v-if="contact.type === 'position error' && contact.error"
                                            id="tag"
                                            :ref="contact.personId"
                                            class="tag position"
                                            @click.prevent="showRecipients(contact)"
                                        >
                                            <font-awesome-icon :icon="['fal', 'hammer']" />
                                            <span>
                                                {{ contact.name }}
                                                ({{ getRecipients(contact).length }}
                                                {{ getRecipients(contact).length > 1 ?
                                                    getLang('sms-dialog.mottakere') :
                                                    getLang('sms-dialog.mottaker') }})
                                            </span>
                                            <button @click.stop="removeSelectedContact(contact)">
                                                <font-awesome-icon :icon="['fal', 'times']" />
                                            </button>
                                        </div>
                                        <div
                                            v-if="
                                                (contact.type !== 'team' || contact.type !== 'position')
                                                    && contact.error
                                            "
                                            id="tag"
                                            ref="tag-{{contact.id}}"
                                            class="tag person error"
                                        >
                                            <span v-if="contact.name !== ''">
                                                {{ contact.name }} ({{ contact.mobile }})
                                            </span>
                                            <span v-if="contact.name === ''">{{ contact.mobile }}</span>
                                            <button @click.stop="removeSelectedContact(contact)">
                                                <font-awesome-icon :icon="['fal', 'times']" />
                                            </button>
                                        </div>
                                    </div>
                                    <input
                                        ref="manualinput"
                                        v-model="manualContactInput"
                                        autofocus
                                        :placeholder="getLang('sms-dialog.Ny mottaker')"
                                        @keyup="handleKeyup"
                                        @keydown.backspace="backspace()"
                                        @blur="addManualContact()"
                                    >
                                </div>

                                <div class="sms-label">
                                    {{ trans('sms-dialog.Melding') }}:
                                </div>
                                <textarea
                                    v-model="smsObj.message"
                                    class="message-field"
                                    :class="{
                                        warning: smsObj.warnings.showSpecialCharWarn
                                            || smsObj.warnings.showMaxTenWarn
                                            || smsObj.warnings.showLimitWarn, danger: smsObj.msgCount > 15,
                                    }"
                                    spellcheck="false"
                                />
                                <div class="bottom-information">
                                    <span class="sms-label">
                                        {{ trans('sms-dialog.Antall tegn') }}: {{ smsObj.charCount }}
                                        ({{ smsObj.msgCount }} {{ smsObj.msgCount > 1 ?
                                            getLang('sms-dialog.meldinger') :
                                            getLang('sms-dialog.melding') }})
                                        {{ trans('sms-dialog.til') }} {{ totalRecipients }} {{ totalRecipients > 1 ?
                                            getLang('sms-dialog.mottakere') :
                                            getLang('sms-dialog.mottaker') }}
                                        = {{ totalMessages }} {{ totalMessages > 1 ?
                                            getLang('sms-dialog.meldinger') :
                                            getLang('sms-dialog.melding') }}.
                                    </span>
                                </div>

                                <div
                                    v-show="
                                        smsObj.warnings.showSpecialCharWarn ||
                                            smsObj.warnings.showMaxTenWarn ||
                                            smsObj.warnings.showExceededMsg"
                                    class="warnings"
                                    :class="{ danger: smsObj.msgCount > 15 }"
                                >
                                    <div class="icon">
                                        <font-awesome-icon
                                            size="lg"
                                            :icon="['fal', 'exclamation-triangle']"
                                        />
                                    </div>
                                    <div class="list">
                                        <ul>
                                            <li v-show="smsObj.warnings.showSpecialCharWarn">
                                                <!-- eslint-disable -->
                                                <span>
                                                    {{ trans('sms-dialog.Spesialtegn minsker størrelsen på hver melding') }}
                                                </span>
                                                <kk-tooltip
                                                    :text="trans('sms-dialog.Spesialtegn halverer størrelsen på meldingene du kan sende. Unngå slike tegn hvis meldingen blir lang') /* eslint-disable-line */"
                                                    position="top"
                                                >
                                                    <font-awesome-icon
                                                        size="lg"
                                                        :icon="['fal', 'question-circle']"
                                                    />
                                                </kk-tooltip>
                                                <!-- eslint-enable -->
                                            </li> <!-- Special Characters Warning -->
                                            <li
                                                v-show="
                                                    smsObj.warnings.showMaxTenWarn
                                                        && !smsObj.warnings.showExceededMsg"
                                            >
                                                <span>
                                                    {{ trans('sms-dialog.Vi anbefaler maksimum 10 meldinger') }}
                                                </span>
                                                <kk-tooltip
                                                    :text="trans('sms-dialog.For å kunne garantere at alle mottakere kan motta meldingen anbefaler vi maks 10 meldinger') /* eslint-disable-line */"
                                                    position="top"
                                                >
                                                    <font-awesome-icon
                                                        size="lg"
                                                        :icon="['fal', 'question-circle']"
                                                    />
                                                </kk-tooltip>
                                            </li> <!-- 10 Length Warning -->

                                            <li v-show="smsObj.warnings.showExceededMsg">
                                                <span>
                                                    {{ trans('sms-dialog.Du har overskredet grensen på 15 meldinger') }}
                                                </span>
                                                <kk-tooltip
                                                    :text="trans('sms-dialog.Vennligst reduser antall meldinger til 15 eller mindre for å fortsette') /* eslint-disable-line */"
                                                    position="top"
                                                >
                                                    <font-awesome-icon
                                                        size="lg"
                                                        :icon="['fal', 'question-circle']"
                                                    />
                                                </kk-tooltip>
                                            </li> <!-- Over 15 Length Warning -->
                                        </ul>
                                    </div>
                                </div> <!-- Error messages for when users pass 10 and 15 messages -->

                                <div id="link-wrapper">
                                    <div class="sms-label">
                                        <font-awesome-icon
                                            size="sm"
                                            :icon="['fal', 'link']"
                                        />
                                        <span>
                                            {{ trans('sms-dialog.Link') }}
                                            ({{ trans('sms-dialog.blir inkludert i bunnen av meldingen') }}):
                                        </span>
                                    </div>
                                    <input
                                        v-model="smsObj.link"
                                        type="text"
                                        class="form-component"
                                        disabled
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template #footer>
                <div class="sms-footer">
                    <kk-button
                        type="basic"
                        class="cancel-button"
                        icon
                        @click.prevent="cancel()"
                    >
                        {{ trans('shared.Avbryt') }}
                    </kk-button>
                    <kk-button
                        type="primary"
                        class="send-button"
                        icon
                        :disabled="smsObj.msgCount > 15"
                        @click.prevent="send()"
                    >
                        <font-awesome-icon
                            class="send-button-icon"
                            :icon="['fal', 'paper-plane']"
                        />
                        {{ trans('shared.Send') }}
                    </kk-button>
                </div>
            </template>
        </kk-modal-v2>

        <!--POPUP TO DISPLAY RECIPIENTS-->
        <kk-popup-desktop
            v-show="showRecipientsPopup"
            :ref="'receiverspopup'"
            class="receivers-popup"
            :style="{
                left: receiversPopupRect.left + 'px',
                top: receiversPopupRect.top + 'px',
            }"
            @hide="showRecipientsPopup = false"
        >
            <template #content>
                <div class="popup">
                    <table class="popup-table">
                        <tr
                            v-for="receiver in selectedRecipient.recipients"
                            :key="receiver.identifier"
                        >
                            <td>
                                <font-awesome-icon :icon="['fal', 'user']" />
                                {{ receiver.name }}
                            </td>
                            <td>
                                <font-awesome-icon :icon="['fal', 'mobile-alt']" />
                                {{ receiver.mobile }}
                            </td>
                        </tr>
                    </table>
                </div>
            </template>
        </kk-popup-desktop>
    </div>
</template>

<script src="./kk-sms-dialog.js"></script>

<style lang="scss" scoped>
@import '../../sass/variables.scss';

/* IE-HACK */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    :deep(.kk-contacts) {
        .list {
            background-color: white;
            overflow: auto;
            height: 300px;
        }
        .list__contact {
            background-color: white;
        }
        .option__person {
            background-color: white;
            border: none;
        }
        .option__contact {
            background-color: white;
            border: none;
        }
        .kk-checkbox .success .check .circle .small {
            border: 1px gray;
        }
        .search {
            background-color: white;
        }
    }
}

*, *:before, *:after {
    box-sizing: border-box;
}

.kk-contacts:deep() {
    width: 100% !important;
    max-height: 150px;
    .list {
        border: 1px solid #D6D5D5;
        border-radius: 2px;
        background-color: #FFFFFF;
    }
    .search {
        height: 35px;
        border: 1px solid #CED4DA;
        border-radius: 17.5px;
        background-color: #FFFFFF;

        .kk-icon-button {
            button.kk-icon-button-wrapper{
                margin-right: var(--spacing-0_25);
                border-radius: var(--radius-full);
            }
        }
    }
}

.sender-select {
    width: 100% !important;

    :deep() {
        .vs__dropdown-toggle {
            min-height: 35px;
        }

        .vs__dropdown-option {
            display: flex;
            align-items: center;
            min-height: 35px;
            white-space: normal;
        }
    }
}

.sub-title {
    height: 16px;
    color: #332E38;
    font-family: "Work Sans";
    font-size: 14px;
    letter-spacing: 0.1px;
    margin-top: 8px;
    margin-bottom: 8px;
}
.content-wrapper {
    display: flex;
    gap: 20px;
}
.right-scroll-wrapper {
    height: 500px;
    padding-right: 10px;
    overflow-y: auto;
    overflow-x: hidden;
}

.sms-right {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 500px;
}

.sms-left {
    width: 280px;
    padding: 20px;
    border: 1px solid #D5D5D5;
    background-color: #F9F9F9;
}

.form-component {
    height: 30px !important;
    width: 100%;
    border-radius: 2px!important;
    border: 1px solid #D6D5D5;
    resize: none;
    box-shadow: none;
    &:hover {
        box-shadow: none;
    }
}
.message-field {
    height: 190px;
    width: 100%;
    border-radius: 2px!important;
    border: 1px solid #D6D5D5;
    resize: none;

    &.warning {
        border: 1px solid $warning;

        &:focus {
            outline: 1px solid $warning;
        }
    }

    &.danger {
        border: 1px solid $danger;

        &:focus {
            outline: 1px solid $danger;
        }
    }
}

.receivers-component {
    min-height: 30px;
    width: 100%;
    border-radius: 2px!important;
    border: 1px solid #D6D5D5;
    display: inline-flex;
    flex-wrap: wrap;
    padding-left: 5px;
    padding-bottom: 3px;
    max-height: 137px;
    overflow: auto;

    input {
        border: none;
        height: auto;
        min-height: 30px;
        outline: none;
        padding-left: 5px;
        flex: 1;
    }
}
.tag {
    border-radius: 3px;
    background-color: var(--primary);
    color: white;
    border: none;
    height: 20px;
    margin-top: 5px;
    margin-right: 2px;
    margin-left: 2px;
    padding: 2px;
    padding-left: 5px;
    padding-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    &.error {
        background-color: var(--danger);
    }

    &.person {
        background-color: var(--primary);
        &.error {
            background-color: var(--danger);
        }
    }
    &.team {
        background-color: var(--green-600);
        cursor: pointer;
        &.error {
            background-color: var(--danger);
        }
    }
    &.position {
        background-color: var(--yellow-600);
        cursor: pointer;
        &.error {
            background-color: var(--danger);
        }
    }

    button {
        line-height: 10px;
        border: none;
        background: transparent;
        outline: none;
        color: white;
        padding: 5px;
    }
    button:hover {
        color: black;
    }
    i {
        margin: 2px;
    }
    svg {
        margin: 2px;
    }
}
.sms-label {
    padding: 5px 0;

    svg {
        margin-right: var(--spacing-1);
    }
}
.bottom-information {
    bottom: 0;
}
.bottom-buttons {
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.error-title {
    color: var(--text-danger);
}

.popup-table {
    width: 300px;
}

.send-button-icon {
    margin-right: 10px;
}

.kk-modal-v2 :deep() {
    .sms-footer {
        width: 100%;
        justify-content: space-between;
        display: flex;
    }
;
}
.receivers-popup {
    position: fixed;
    z-index: 9999;
}

.cancel-button {
    margin-right: 40px;
}

.warnings {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin: 5px 0;
    padding: 3px 0;
    width: 100%;
    color: var(--color-dark-text);
    line-height: 1.4;
    border-radius: 2px;
    background-color: var(--warning);

    &.danger {
        background-color: var(--danger);
        color: white;
    }

    .list {
        width: 100%;

        ul {
            padding: 0;
            margin: 0;
            list-style-type: none;
        }

        li {
            display: flex;
            justify-content: space-between;
            margin: 3px 0;

            .tooltip:deep() {
                cursor: pointer;
                padding-right: 6px;

                .text {
                    left: -69px;
                }

                svg:hover {
                    color: black;
                }
            }
        }
    }

    .icon {
        margin: 0 10px;

        i {
            font-size: 16px;
            font-weight: 300;
        }
    }
}
</style>
