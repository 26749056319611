<template>
    <section>
        <button class="header-icon-button" @click="toggleModal">
            <fa-icon :icon="['fal', 'comment-smile']" />
            <span>{{ trans('feedback.Tilbakemelding') }}</span>
        </button>

        <kk-modal-v2
            v-if="showFeedbackModal"
            size="medium"
            type="separated"
            :loading="isLoading"
            :ok-button-label="trans('feedback.Send tilbakemelding')"
            :ok-button-disabled="isLoading"
            @ok="doSendFeedback"
            @hide="toggleModal"
            @click-outside="toggleModal"
        >
            <template #title>
                {{ trans("feedback.Tilbakemeldingsskjema") }}
            </template>

            <template #content>
                <form class="feedback-form">
                    <section class="info">
                        <p>
                            {{ trans('feedback.Tilbakemeldingen din er avgjørende for å hjelpe oss å gi deg den beste mulige opplevelsen. I tillegg, hvis det er noe du misliker eller noe du er spesielt glad for, vil vi gjerne høre om det!') }}
                        </p>
                        <required-field show-required-text />
                    </section>
                    <section class="radios">
                        <kk-checkbox
                            v-for="option in typeOptions"
                            :key="option.value"
                            :modelValue="isTypeSelected(option.value)"
                            shape="circle"
                            @update:modelValue="setFormType(option.value)"
                        >
                            {{ option.text }}
                        </kk-checkbox>
                    </section>

                    <section :class="['text-area', { error: showError }]">
                        <label>
                            <span>
                                {{ trans('feedback.Tilbakemelding') }}
                                <required-field />
                            </span>
                            <kk-textarea v-model="form.text" />
                        </label>
                        <p v-show="showError">
                            {{ trans('feedback.Vennligst fyll inn tilbakemelding') }}
                        </p>
                    </section>
                </form>
            </template>
        </kk-modal-v2>
    </section>
</template>

<script>

// Libs
import axios from 'axios';
import { logThrowApiError } from '@/libs/kk-api';
import { namespace as TOAST_NAMESPACE, TOAST_STORE } from '@/stores/modules/toast';
// Components
import { kkModalV2, kkCheckbox, kkTextarea } from 'vue-components';
import requiredField from '@/components/required-field/required-field.vue';

export default {
    name: 'send-feedback',

    components: {
        kkModalV2,
        kkCheckbox,
        kkTextarea,
        requiredField,
    },

    data() {
        return {
            showFeedbackModal: false,
            form: {
                type: 'neutral',
                text: '',
                url: null,
            },
            formError: false,
            typeOptions: [
                {
                    text: this.trans('feedback.Jeg har et forslag'),
                    value: 'neutral',
                },
                {
                    text: this.trans('feedback.Jeg er ikke fornøyd med noe'),
                    value: 'negative',
                },
                {
                    text: this.trans('feedback.Jeg er veldig fornøyd med'),
                    value: 'positive',
                },
            ],
            isLoading: false,
        };
    },

    computed: {
        showError() {
            return this.formError && !this.form.text.length;
        },
    },

    methods: {
        isFormValidated() {
            if (!this.form.text.length > 0) {
                this.formError = true;

                return false;
            }

            this.formError = false;

            return true;
        },
        async doSendFeedback() {
            const url = '/api/feedback/publish';
            const body = {
                ...this.form,
                url: this.getCurrentURL(),
            };
            const isValidated = this.isFormValidated();

            if (!isValidated) {
                return;
            }

            this.isLoading = true;

            try {
                await axios.patch(url, body);

                this.$store.dispatch(`${TOAST_NAMESPACE}/${TOAST_STORE.ACTIONS.CREATE_SUCCESS_TOAST}`, {
                    text: this.trans('email-log.Tilbakemelding sendt'),
                    subText: this.trans('feedback.Tusen takk. Vi setter pris på dine innspill og tar alle tilbakemeldinger på alvor.'),
                    duration: 6000,
                });
                this.toggleModal();
            } catch (error) {
                logThrowApiError(error, url, body, true);
            } finally {
                this.isLoading = false;
            }
        },
        setFormType(value) {
            this.form.type = value;
        },
        getCurrentURL() {
            return window.location.href;
        },
        isTypeSelected(value) {
            return this.form.type === value;
        },
        toggleModal() {
            this.resetForm();
            this.showFeedbackModal = !this.showFeedbackModal;
        },
        resetForm() {
            this.form = {
                type: 'neutral',
                text: '',
                url: null,
            };
            this.formError = false;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~sass/_variables.scss';
.feedback-form {
    display: grid;
    grid-template-columns: 1fr;
    gap: var(--spacing-2);

    section {
        &.info {
            font: var(--font-body-sm-regular);
            color: var(--text-secondary);

            p {
                margin: 0 0 var(--spacing-2) 0;
            }
        }
        &.radios {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            gap: var(--spacing-1);
        }
        &.text-area {
            label {
                width: 100%;
                color: var(--text-primary);
            }
            p {
                margin-top: var(--spacing-0_5);
            }
        }
    }

    .kk-textarea:deep() {
        margin-top: var(--spacing-0_5);

        textarea {
            resize: none;
            outline: none;
        }
    }

    .error {
        color: var(--text-danger);

        .kk-textarea:deep() {
            border: 2px solid var(--danger);
            border-radius: var(--radius-sm);
        }
    }
}
</style>
